import React, { useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { FaShare } from "react-icons/fa";
import Fetching from "../../Fetching";
import SaveProperty from "../../SaveProperty";
import FeaturedAgent from "../../FeaturedAgent";
import Breadcrumbs from "../../Breadcrumbs";
import useOnScreen from "../../../hooks/useOnScreen";
import { Share, Contact } from "./";
import { useNacLocation } from "../../../hooks/useNacLocation";

/**
 * Listing page header component
 */
const HeaderBar = () => {
    const listings = useSelector((state) => state.pages.listings);
    const featuredAgent = useSelector((state) => state.agents.featured);
    const { state: { listingId = undefined } = {} } = useNacLocation();
    const listing = listings?.[listingId];
    const { headerLinks, pageHeaderInfo, servicingInfo, byBrokerAgentID } =
        listing || {};
    const listingShareImage = listing?.images?.[0]?.uri || undefined;
    const { listingNumber } = listing?.identifiers || {};

    const footerRef = useRef(null);
    const isFooterVisible = useOnScreen(footerRef);

    if (typeof window !== "undefined" && window !== null) {
        // bit of a hack-a-roni to get a ref for the footer here without passing the ref through props
        if (footerRef && !footerRef.current) {
            footerRef.current = document.getElementById("footer-container");
        }
    }

    return (
        <Fetching waitUntil={listing}>
            <div data-testid="listing-header">
                <StyledWrapper className="d-flex align-items-center justify-content-between">
                    <Breadcrumbs
                        className="d-block d-md-none overflow-hidden"
                        crumbs={headerLinks}
                    />
                    <Contact
                        color="primary"
                        className="inverse me-2 px-0 flex-grow-1 d-none d-md-block"
                        contactType="Showing"
                        header="Schedule a showing"
                        style={{ maxWidth: "175px" }}
                        featuredAgent={featuredAgent}
                    >
                        <span className="d-none d-lg-inline">Schedule a</span>{" "}
                        Showing
                    </Contact>
                    <Contact
                        color="primary"
                        className="me-2 px-0 flex-grow-1 d-none d-md-block"
                        style={{ maxWidth: "175px" }}
                        featuredAgent={featuredAgent}
                    >
                        <span className="d-none d-lg-inline">Request </span>More
                        Info
                    </Contact>
                    <Share
                        media={listingShareImage}
                        pageHeaderInfo={pageHeaderInfo}
                        listingId={listingId}
                        color="primary"
                        className="icon-button inverse px-0 flex-grow-1 me-2 ms-auto ms-md-0"
                    >
                        <FaShare />
                        <span className="d-none d-md-inline ms-1">Share</span>
                    </Share>
                    <SaveProperty
                        listingNumber={listingNumber}
                        location={"Listing"}
                        color="primary"
                        className="icon-button inverse px-0 flex-grow-1 me-0 me-md-2"
                    >
                        {(isSavedProperty) => (
                            <span className="d-none d-md-inline ms-1">
                                Save{isSavedProperty && "d"}
                            </span>
                        )}
                    </SaveProperty>
                    <SlideUp className={`${isFooterVisible ? "hide" : ""}`}>
                        <FeaturedAgent
                            data-test="inline-featured-agent"
                            className="w-100 ms-lg-auto px-3 py-2 p-lg-0 featured-agent"
                            showPhoneAsLink={true}
                            showAgentTitle={false}
                            servicingInfo={servicingInfo}
                            byBrokerAgentID={byBrokerAgentID}
                        />
                    </SlideUp>
                </StyledWrapper>
            </div>
        </Fetching>
    );
};

const StyledWrapper = styled.div`
    .btn.icon-button {
        max-width: 175px;
        @media (max-width: 768px) {
            flex-grow: 0 !important;
            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;
            margin-right: 0.5rem !important;
            background: none;
            border: 0 none;
        }
    }
`;

const SlideUp = styled.div`
    @media (max-width: 992px) {
        position: fixed;
        bottom: 0;
        width: 100vw;
        margin-left: -1rem;
        background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
        border-top: var(--bs-border-width) var(--bs-border-style)
            var(--bs-border-color);
        z-index: 1001;
        transition: all 0.5s ease-out;
        &.hide {
            bottom: -100px;
        }
        .featured-agent {
            z-index: 1001;
        }
    }
    @media (max-width: 768px) {
        margin-left: -0.25rem;
    }
`;

export default HeaderBar;
