import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import InfoWindow from "./InfoWindow";
import { useTrackEvent } from "../../hooks/useTrackEvent";

// NOTE -- you must pass lat/lng as props to this component. They are not used in this component but google-maps-react
// requires them for the marker to show up on the map.
const CulsterMarker = ({ cluster, supercluster, map, listingsPreviews }) => {
    const [hovered, setHovered] = useState(false);
    const { trackGTM } = useTrackEvent();
    const [longitude, latitude] = cluster.geometry.coordinates;
    const { point_count: pointCount } = cluster.properties;

    const selectedInState = useSelector((state) => {
        const clusterFound = supercluster
            .getLeaves(cluster.id, pointCount)
            .find(
                (listing) =>
                    listing.properties.listingId === state.map.activeMarker
            );
        return clusterFound ? state.map.activeMarker : undefined;
    });

    const marker = listingsPreviews.find(
        (preview) => preview.listingId === selectedInState
    );
    const radius = 10 + (pointCount / supercluster.points.length) * 25;
    const isSelected = selectedInState !== undefined;

    return (
        <StyledMarker
            className="marker position-relative"
            data-test="cluster-marker"
            offset={radius}
            onClick={() => {
                const expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom(cluster.id),
                    20
                );
                map.setZoom(expansionZoom);
                map.panTo({ lat: latitude, lng: longitude });
                trackGTM({
                    event: `clusterExpand`,
                    action: "click",
                    category: "mapInteraction",
                    type: "Cluster Marker Expantion",
                });
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <InfoWindow marker={marker} selected={isSelected} />
            <svg height={radius * 2} width={radius * 2}>
                <circle
                    cx="50%"
                    cy="50%"
                    r={radius}
                    stroke="white"
                    strokeWidth="2"
                    fill={hovered || isSelected ? "#ff0000" : "#0077C0"}
                />
                <text
                    x="50%"
                    y="50%"
                    stroke="#ffffff"
                    textAnchor="middle"
                    dy=".3em"
                >
                    {pointCount}
                </text>
            </svg>
        </StyledMarker>
    );
};

const StyledMarker = styled.div`
    cursor: pointer;
    .info {
        z-index: 1030;
        white-space: nowrap;
        left: 50%;
        bottom: ${(props) => props.offset * 2}px;
        margin-left: ${(props) => props.offset}px;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        &.listing {
            min-width: 120px;
            white-space: inherit;
        }
    }
`;

export default CulsterMarker;
