import React, { useEffect } from "react";
import { useTrackEvent } from "../../hooks/useTrackEvent";

/**
 * Cordless Media Telco Widget widget
 * https://cdn.cordlessmedia.com/documentation/index.html#telco.md
 * This is just the html tag portion of the implementation. The JS is added in Google Tag Manager.
 */
const Telco = ({ zipCode, address, aptNumber, lat, long }) => {
    const { trackGTM } = useTrackEvent();

    useEffect(() => {
        trackGTM({
            event: "telco",
            type: "cordlessMedia",
            location: "Listing Page",
            zipCode,
            address,
            aptNumber,
            lat,
            long,
        });
    }, []);

    return <div id="cmtcwidget" className="check-content"></div>;
};

export default Telco;
