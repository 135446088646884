import React, { useEffect } from "react";
import ContextToggle from "../../../ContextToggle";
import ContextToggleChild from "../../../ContextToggleChild";
import { useMapContext } from "../../../../contexts/map";
import { useTrackEvent } from "../../../../hooks/useTrackEvent";
import { useQueryParam, StringParam } from "use-query-params";

const Terrain = ({ className, style }) => {
    const [mapTerrain, setMapTerrain] = useQueryParam(
        "mapTerrain",
        StringParam
    );
    const {
        state: { map },
    } = useMapContext();
    const { trackGTM } = useTrackEvent();

    const handleToggle = (terrainType) => {
        setMapTerrain(terrainType);
        trackGTM({
            event: `${terrainType}Toggle`,
            action: "click",
            category: "mapInteraction",
            type: `Controlls Terrain ${terrainType}`,
        });
    };

    useEffect(() => {
        if (map) {
            map.setMapTypeId(mapTerrain || "roadmap"); // mapTerrain is roadmap when it is undefined
        }
    }, [mapTerrain, map]);

    return (
        <>
            <ContextToggle
                className={className}
                style={style}
                dataTest="terrain-toggle"
            >
                <ContextToggleChild
                    toggle={() => handleToggle()}
                    isSelected={mapTerrain !== "satellite"}
                    className="flex-grow-1 text-center street-view"
                    dataTest="street-view-btn"
                >
                    Street
                </ContextToggleChild>
                <ContextToggleChild
                    toggle={() => handleToggle("satellite")}
                    isSelected={mapTerrain === "satellite"}
                    className="flex-grow-1 text-center satellite-view"
                    dataTest="satellite-view-btn"
                >
                    Satellite
                </ContextToggleChild>
            </ContextToggle>
        </>
    );
};

export default Terrain;
