import React from "react";
import RangeSlider from "../../RangeSlider";
import { useRangeSliderSearch } from "./hooks/useRangeSliderSearch";

/**
 * The Range Slider search component.
 * @param {string} units - The unit of measurement for the range.
 * @param {boolean} disabled - Determines whether the Slider should be disabled or not.
 * @returns The Range Slider component that is used in the Horizontal Search.
 */
const RangeSliderSearch = ({ units = "mi", disabled = false }) => {
    const { range, handleChange, handleChangeEnd } = useRangeSliderSearch();

    return (
        <RangeSlider
            range={range}
            units={units}
            disabled={disabled}
            handleSetRadius={handleChange}
            handleSetRadiusEnd={handleChangeEnd}
        />
    );
};

export default RangeSliderSearch;
