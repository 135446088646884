import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setMarkers } from "../../../reducers/mapReducer";
import { useGetListing } from "../../../hooks/listing";
import useExecuteScript from "../../../hooks/useExecuteScript";
import bulkPageReducer from "../../../reducers/bulkPageReducer";
import { getPageType } from "../../../support/helpers";
import { setPageError } from "../../../reducers/rootReducer";
import { setPageHeaderInfo } from "../../../reducers/pagesReducer";
import { useTrackEvent } from "../../../hooks/useTrackEvent";
import { useNacLocation } from "../../../hooks/useNacLocation";

const ListingProvider = ({ children }) => {
    const dispatch = useDispatch();
    const listings = useSelector((state) => state.pages.listings);
    const { execute, error: getListingsError } = useGetListing();
    const runJs = useExecuteScript();
    const { trackGTM } = useTrackEvent();
    const routeParams = useParams();
    const isListingPage = getPageType(routeParams, "listing");
    const {
        state: { stateId, nicheItemId, listingId, isOffmarket } = {},
        error,
    } = useNacLocation();
    const listing = listings?.[listingId];
    const { identifiers, isListTrac = false } = listing || {};

    useEffect(() => {
        if (listing?.pageHeaderInfo && getPageType(routeParams, "listing")) {
            dispatch(setPageHeaderInfo(listing?.pageHeaderInfo));
        }

        if (listing?.trackingInfo) {
            listing.trackingInfo.forEach((ts) => {
                runJs(ts.content);
            });
        }
        if (listing) {
            const { highlights, address } = listing;
            const highlightData = {};
            highlights.forEach((h) => (highlightData[h.id] = h.value));
            trackGTM({
                event: "pageView",
                type: "Listing",
                listingId,
                address,
                isListTrac,
                ...highlightData,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listing, routeParams]);

    useEffect(() => {
        if (listingId && !listing && isListingPage) {
            const fetchData = async () => {
                bulkPageReducer(dispatch, {
                    pageType: "listing",
                    ...(await execute(
                        stateId,
                        nicheItemId,
                        listingId,
                        isOffmarket
                    )),
                });
            };
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listingId, isListingPage]);

    useEffect(() => {
        if (isListingPage && identifiers) {
            dispatch(
                setMarkers([
                    {
                        ...identifiers,
                        type: "none",
                    },
                ])
            );
        }
    }, [dispatch, identifiers, listingId]);

    useEffect(() => {
        if (error || getListingsError) {
            dispatch(
                setPageError({
                    page: "Listing",
                    error: "Error loading listing page.",
                })
            );
        }
    }, [dispatch, error, getListingsError]);

    return children;
};

export default ListingProvider;
