import React, { useEffect } from "react";
import { useTrackEvent } from "../../hooks/useTrackEvent";

/**
 * Cordless Media Mortgage Calculator widget
 * https://ads.cordlessmedia.com/documentation/index.html#display.md
 * This is just the html tag portion of the implementation. The JS is added in Google Tag Manager.
 */
const MortgageCalculator = ({
    zip,
    address,
    apt,
    price,
    downPayment,
    interestRate,
    hoa,
    homeIns,
    propTax,
}) => {
    const { trackGTM } = useTrackEvent();

    useEffect(() => {
        trackGTM({
            event: "mortgageCalculator",
            type: "cordlessMedia",
            location: "Listing Page",
            zip,
            address,
            apt,
            price,
            downPayment,
            interestRate,
            hoa,
            homeIns,
            propTax,
        });
    }, []);

    return <div id="cmwidget"></div>;
};

export default MortgageCalculator;
