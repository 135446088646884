import React from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import PubSub from "pubsub-js";
import { useParams } from "react-router-dom";
import {
    getAssembliesByGroup,
    getAssemblyItem,
    getLabelByValue,
    getPageType,
    resolveLocationStateItem,
} from "../../../support/helpers";
import { buildPriceRangeLabel, buildMegaLabel } from "../helpers";
import SearchItem from "../SearchItem";
import LocationSelect from "../LocationSelect";
import SaveSearch from "../SaveSearch";
import MegaAssociates from "../MegaAssociates";
import {
    useQueryParams,
    StringParam,
    NumberParam,
    DelimitedArrayParam,
} from "use-query-params";
import { useSearchData } from "../../../hooks/useSearchData";
import { useNacLocation } from "../../../hooks/useNacLocation";
import PriceSearch from "./PriceSearch";
import WaterfrontSearch from "./WaterfrontSearch";
import BedroomsSearch from "./BedroomsSearch";
import BathroomsSearch from "./BathroomsSearch";
import PropertyTypeSearch from "./PropertyTypeSearch";
import RangeSliderSearch from "./RangeSliderSearch";

const assemblyName = "USER_SEARCH";
const assemblyGroup = "USER_SEARCH";

const HorizontalSearch = ({ className, isOffmarket = false }) => {
    const [locationQuery] = useQueryParams({
        search: StringParam,
        zip: NumberParam,
        range: NumberParam,
    });
    const [query] = useQueryParams({
        price_min: StringParam,
        price_max: StringParam,
        waterfront: NumberParam,
        bedrooms: NumberParam,
        bathrooms: NumberParam,
        property_type: StringParam,
        mega: DelimitedArrayParam,
    });
    const { hasSearched } = useSearchData();
    const isMobile = useSelector((state) => state.root.isMobile);
    const assemblies = useSelector((state) => state.root.assemblies);
    const nicheItems = useSelector((state) => state.pages.nicheItems);

    const defaultRange = useSelector((state) => state.search.defaultRange);
    const isFetching = useSelector((state) => state.search.isFetching);
    const { state: { nicheItemId } = {} } = useNacLocation();
    const niche_item_id =
        nicheItemId || resolveLocationStateItem("nicheItemId");
    const assembly = getAssembliesByGroup(
        assemblies?.[assemblyName],
        assemblyGroup
    );
    const propertyTypeAssemblyItem = getAssemblyItem(assembly, "property_type");
    const routeParams = useParams();
    const isState = getPageType(routeParams, "state");
    const isNicheItemPage = getPageType(routeParams, "niche");
    const searchItemDisabled =
        isFetching !== undefined ||
        (locationQuery?.zip === undefined && isState);
    const selectedNicheItem = nicheItems?.[niche_item_id];
    const megaAssociates = selectedNicheItem?.megaAssociates;
    const disableTooltip = isState && !hasSearched;
    const range = locationQuery.range || defaultRange;

    if (!niche_item_id && isNicheItemPage) return null;

    return (
        <div className={className} id="search-container">
            <div className="col d-flex w-100 position-relative" id="searchbar">
                <div
                    className={`me-2 flex-grow-1 d-none ${
                        isState ? "d-lg-block" : ""
                    }`}
                    style={{ maxWidth: "280px" }}
                >
                    <LocationSelect className="w-100" />
                </div>
                <SearchItem
                    className={`d-none ${
                        !isState ? "" : "me-2 flex-grow-1 d-xl-block"
                    }`}
                    style={{ maxWidth: "280px" }}
                    label={`${range}mi`}
                    isMobile={isMobile}
                    disabled={searchItemDisabled}
                    disableTooltip={disableTooltip}
                    displayIn={"nowrapper"}
                >
                    {() => (
                        <RangeSliderSearch
                            disabled={isFetching !== undefined}
                        />
                    )}
                </SearchItem>
                <SearchItem
                    className={`d-xl-none me-2 ${!isState ? "d-none" : ""}`}
                    label={`${range}mi`}
                    isMobile={isMobile}
                    disabled={searchItemDisabled}
                    disableTooltip={disableTooltip}
                    displayIn={"dropdown"}
                >
                    {(isOpen, toggleIsOpen) => (
                        <RangeSliderSearch
                            disabled={isFetching !== undefined}
                        />
                    )}
                </SearchItem>
                {!isOffmarket && (
                    <SearchItem
                        className="d-none d-md-block me-2"
                        label={buildPriceRangeLabel(
                            query?.price_min,
                            query?.price_max
                        )}
                        isMobile={isMobile}
                        disabled={searchItemDisabled}
                        disableTooltip={disableTooltip}
                    >
                        {() => <PriceSearch />}
                    </SearchItem>
                )}
                {!isOffmarket && (
                    <SearchItem
                        className="d-none d-md-block me-2"
                        label={`Show: ${
                            query?.waterfront ? "Waterfront" : "All"
                        }`}
                        isMobile={isMobile}
                        disabled={searchItemDisabled}
                        disableTooltip={disableTooltip}
                        showMenuFooter={true}
                    >
                        {(isOpen, toggleIsOpen) => (
                            <WaterfrontSearch toggleIsOpen={toggleIsOpen} />
                        )}
                    </SearchItem>
                )}
                <SearchItem
                    className="d-none d-md-block me-2"
                    label={`${
                        query?.bedrooms ? `${query?.bedrooms}+ ` : ""
                    }Beds`}
                    isMobile={isMobile}
                    disabled={searchItemDisabled}
                    disableTooltip={disableTooltip}
                    showMenuFooter={true}
                >
                    {(isOpen, toggleIsOpen) => (
                        <BedroomsSearch
                            className="mt-2"
                            toggleIsOpen={toggleIsOpen}
                        />
                    )}
                </SearchItem>
                <SearchItem
                    className="d-none d-md-block me-2"
                    label={`${
                        query?.bathrooms ? `${query?.bathrooms}+ ` : ""
                    }Baths`}
                    isMobile={isMobile}
                    disabled={searchItemDisabled}
                    disableTooltip={disableTooltip}
                    showMenuFooter={true}
                >
                    {(isOpen, toggleIsOpen) => (
                        <BathroomsSearch toggleIsOpen={toggleIsOpen} />
                    )}
                </SearchItem>
                <SearchItem
                    className="d-none d-md-block me-2"
                    label={getLabelByValue(
                        propertyTypeAssemblyItem?.listInfo?.list,
                        query?.property_type,
                        "Property Type"
                    )}
                    displayRight={true}
                    isMobile={isMobile}
                    disabled={searchItemDisabled}
                    disableTooltip={disableTooltip}
                    showMenuFooter={true}
                >
                    {(isOpen, toggleIsOpen) => (
                        <PropertyTypeSearch toggleIsOpen={toggleIsOpen} />
                    )}
                </SearchItem>
                {megaAssociates && (
                    <SearchItem
                        className="d-none d-md-block me-2"
                        label={buildMegaLabel(
                            query?.mega,
                            megaAssociates.dIMegaAssociates,
                            selectedNicheItem.stateId
                        )}
                        displayRight={true}
                        isMobile={isMobile}
                        disabled={searchItemDisabled}
                        disableTooltip={disableTooltip}
                        showMenuFooter={true}
                    >
                        {() => <MegaAssociates location="header search" />}
                    </SearchItem>
                )}
                <SearchItem
                    className="d-md-none me-2"
                    displayRight={true}
                    label="Filters"
                    isMobile={isMobile}
                    disabled={searchItemDisabled}
                    disableTooltip={disableTooltip}
                    showMenuFooter={true}
                    shouldLockFilters
                    reset={(params) => PubSub.publish("RESET_SEARCH", params)}
                    displayIn={isMobile ? "drawer" : "dropdown"}
                >
                    {(isOpen, toggleIsOpen) => (
                        <>
                            {!isOffmarket && (
                                <>
                                    {isState && (
                                        <SearchItemWrapper title={`Radius`}>
                                            <RangeSliderSearch
                                                disabled={
                                                    isFetching !== undefined
                                                }
                                            />
                                        </SearchItemWrapper>
                                    )}
                                    <SearchItemWrapper title="Price">
                                        <PriceSearch />
                                    </SearchItemWrapper>
                                    <div className="d-flex border-bottom pb-3 mb-3">
                                        <div>Tagged as Waterfront:</div>
                                        <div className="ms-auto">
                                            <WaterfrontSearch isToggle />
                                        </div>
                                    </div>
                                </>
                            )}
                            <SearchItemWrapper title="Bedrooms">
                                <BedroomsSearch className="mt-2" />
                            </SearchItemWrapper>
                            <SearchItemWrapper title="Bathrooms">
                                <BathroomsSearch />
                            </SearchItemWrapper>
                            <SearchItemWrapper title="Property Type">
                                <PropertyTypeSearch />
                            </SearchItemWrapper>
                            {megaAssociates && (
                                <SearchItemWrapper>
                                    <MegaAssociates />
                                </SearchItemWrapper>
                            )}
                        </>
                    )}
                </SearchItem>
                {!isState && hasSearched && !isMobile && (
                    <Button
                        color="link"
                        className="px-0 me-2"
                        onClick={() => PubSub.publish("RESET_SEARCH")}
                        data-test="reset-search-button"
                    >
                        Reset Search
                    </Button>
                )}
                <SaveSearch
                    buttonColor={searchItemDisabled ? "gray" : undefined}
                    buttonClasses={`rounded px-2 py-2 ml-auto ${
                        searchItemDisabled ? "border" : ""
                    }`}
                    className="ms-auto"
                    disabled={searchItemDisabled}
                />
            </div>
        </div>
    );
};

const SearchItemWrapper = ({ children, title }) => (
    <div className="border-bottom pb-3 mb-3">
        <div className="mb-1 fw-bold">{title}</div>
        {children}
    </div>
);

export default HorizontalSearch;
