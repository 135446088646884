/*
 * ------------------------------------------------------------------------------------
 * DESCRIPTION:
 * ------------------------------------------------------------------------------------
 *      Contact component
 *
 * ------------------------------------------------------------------------------------
 * VERSION HISTORY: (NOTE: The version variables must be updated manually.)
 * ------------------------------------------------------------------------------------
 *                   Changed By
 *      Date         Initials    Version #  Sub-Version  Notes
 *      -----------  ----------  ---------  -----------  ------------------------------
 *      2022-11-16   BL          22320      1            Re-structured files and renamed to index.js
 *
 */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Alert, Row, Col, Form, FormGroup, Button, Label } from "reactstrap";
import { useForm } from "react-hook-form";
import { setAssemblies } from "../../reducers/rootReducer";
import {
    capitalizeFirstLetter,
    getAssembliesByGroup,
    getPageType,
} from "../../support/helpers";
import IndeterminateButton from "../IndeterminateButton";
import ValidatingField from "../ValidatingField";
import { useGetAssembly } from "../../hooks/useAssembly";
import { useContact } from "../../hooks/useContact";
import { useTrackEvent } from "../../hooks/useTrackEvent";
import Curtain from "../Curtain";
import ScheduleShowing from "./ScheduleShowing";
import { useNacLocation } from "../../hooks/useNacLocation";

const assemblyName = "CONTACT_US";
const assemblyGroup = "CONTACT_US";

function Contact({ contactType = "Agent", agentId = 0, closeModal }) {
    const CONTACT_US_type = contactType;
    const dispatch = useDispatch();
    const routeParams = useParams();
    const pageType = getPageType(
        routeParams,
        null,
        agentId !== 0 ? agentId : undefined
    );
    const sessionID = useSelector((state) => state.root.userSession.sessionID);
    const assemblies = useSelector((state) => state.root.assemblies);
    const { pathname, state: { nicheItemId, listingId } = {} } =
        useNacLocation();
    const [alertData, setAlertData] = useState(undefined);
    const contactForm = useForm({ mode: "all" }); // Must set mode to all for it to validate on blur of all fields. This is to be able to enable/disable the submit button
    const {
        handleSubmit,
        setError,
        formState: { isValid, isDirty },
        getValues,
        reset,
    } = contactForm;
    const { preferred_time, preferred_time2 } = getValues();
    const { execute: getAssembly, isLoading: assemblyIsLoading } =
        useGetAssembly();
    const {
        execute: submitContact,
        isLoading,
        data: contactData,
        error: contactError,
    } = useContact();
    const { trackEvent, trackGTM } = useTrackEvent();
    const contactAssemblyName = `${assemblyName}_${contactType.toUpperCase()}`;
    const assembly = getAssembliesByGroup(
        assemblies?.[contactAssemblyName],
        assemblyGroup
    );

    useEffect(() => {
        if (!assembly) {
            const runGetAssembly = async () => {
                dispatch(
                    setAssemblies({
                        [contactAssemblyName]: await getAssembly(
                            sessionID,
                            `${assemblyGroup}-${contactType}`,
                            true,
                            {
                                CONTACT_US_type,
                            }
                        ),
                    })
                );
            };
            runGetAssembly();
        }
    }, [
        assembly,
        CONTACT_US_type,
        contactAssemblyName,
        sessionID,
        getAssembly,
        contactType,
        dispatch,
    ]);

    useEffect(() => {
        if (isDirty) {
            trackGTM({
                event: `formStarted`,
                action: "type",
                category: "contact",
                type: contactType,
                listingId,
                nicheItemId,
                agentId,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDirty]);

    const onSubmit = (data) => {
        if (alertData) setAlertData(undefined);

        let CONTACT_US_fromType = capitalizeFirstLetter(pageType);
        if (pageType === "development" || pageType === "offmarket") {
            CONTACT_US_fromType = "Niche";
        } else if (pathname === "/agents") {
            CONTACT_US_fromType = "Agent";
        }

        submitContact({
            body: JSON.stringify({
                CONTACT_US_type,
                CONTACT_US_agent_id: agentId,
                CONTACT_US_nicheItem_ID: nicheItemId,
                CONTACT_US_listing_id: listingId,
                CONTACT_US_fromType,
                ...data,
            }),
        });
        trackGTM({
            event: `formSubmit`,
            action: "submit",
            category: "contact",
            type: contactType,
            button_label: "Submit",
            listingId,
            nicheItemId,
            agentId,
        });
    };

    useEffect(() => {
        if (
            contactData !== null &&
            contactData?.entryDefects &&
            !contactError
        ) {
            if (contactData?.entryDefects) {
                contactData.entryDefects.forEach((defect) => {
                    const name = Object.keys(defect)[0];
                    setError(name, { message: defect[name] });
                });
            } else {
                reset();
                setAlertData({
                    color: "success",
                    alertText:
                        "Your contact request has been sent successfully.",
                });
                trackEvent("CONTACT", {
                    SOURCE_INFO: {
                        // GA
                        eventAction: "submit",
                        eventLabel: contactType,
                        eventValue: agentId || listingId || nicheItemId,
                        // FB Lead
                        content_category: contactType,
                        value: agentId || listingId || nicheItemId,
                    },
                });
                if (closeModal) setTimeout(() => closeModal(), 2000);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactData]);

    useEffect(() => {
        if (contactError) {
            setAlertData({
                color: "danger",
                alertText: (
                    <>
                        Sorry, we had an issue submitting your contact request.
                        Please{" "}
                        <Button
                            className="p-0"
                            color="link"
                            onClick={handleSubmit(onSubmit)}
                        >
                            click here to try again
                        </Button>
                        or <Link to="/info/contact-us">contact us</Link> if you
                        continue to have issues.
                    </>
                ),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactError]);

    return (
        <Curtain show={assemblyIsLoading} withLoading>
            {contactData !== null &&
            !contactData?.entryDefects &&
            !contactError ? (
                <>
                    <div className="h4">Thank you for your interest!</div>
                    <div>We will reach out soon.</div>
                    <div className="d-flex mt-3">
                        {closeModal && (
                            <Button
                                color="primary"
                                className="ms-auto"
                                onClick={() => closeModal()}
                            >
                                Close
                            </Button>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <Alert
                        color={alertData?.color}
                        isOpen={alertData !== undefined}
                        toggle={() => setAlertData(undefined)}
                    >
                        {alertData?.alertText}
                    </Alert>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        {CONTACT_US_type === "Showing" && (
                            <Row className="my-3">
                                <ScheduleShowing
                                    name="preferred_time"
                                    {...contactForm}
                                />
                            </Row>
                        )}
                        <Row>
                            <Col sm>
                                <ValidatingField
                                    name="first_name"
                                    assembly={assembly}
                                    {...contactForm}
                                />
                            </Col>
                            <Col sm>
                                <ValidatingField
                                    name="last_name"
                                    assembly={assembly}
                                    {...contactForm}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ValidatingField
                                    name="phone"
                                    assembly={assembly}
                                    {...contactForm}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ValidatingField
                                    name="email"
                                    assembly={assembly}
                                    {...contactForm}
                                />
                            </Col>
                        </Row>
                        {CONTACT_US_type.includes("Agent") && (
                            <Row>
                                <Col sm>
                                    <ValidatingField
                                        className="mb-3 d-flex"
                                        labelClasses="order-last ms-2"
                                        name="buy_property"
                                        type="checkbox"
                                        assembly={assembly}
                                        {...contactForm}
                                    />
                                </Col>
                                <Col sm>
                                    <ValidatingField
                                        className="mb-3 d-flex"
                                        labelClasses="order-last ms-2"
                                        name="sell_property"
                                        type="checkbox"
                                        assembly={assembly}
                                        {...contactForm}
                                    />
                                </Col>
                                <Col sm className="flex-grow-1">
                                    <ValidatingField
                                        className="mb-3 d-flex"
                                        labelClasses="order-last ms-2"
                                        name="market_evaluation"
                                        type="checkbox"
                                        assembly={assembly}
                                        {...contactForm}
                                    />
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col>
                                <ValidatingField
                                    name="comments"
                                    type="textarea"
                                    assembly={assembly}
                                    {...contactForm}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center mt-3">
                            <Col className="d-flex">
                                <FormGroup className="ms-auto">
                                    <IndeterminateButton
                                        type="submit"
                                        className="btn btn-primary"
                                        value="Create Account"
                                        disabled={!isValid || isLoading}
                                        isLoading={isLoading}
                                    >
                                        Submit
                                    </IndeterminateButton>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </>
            )}
        </Curtain>
    );
}

export default Contact;
