/*
 * ------------------------------------------------------------------------------------
 * DESCRIPTION:
 * ------------------------------------------------------------------------------------
 *      EmailAFriend component
 *
 * ------------------------------------------------------------------------------------
 * VERSION HISTORY: (NOTE: The version variables must be updated manually.)
 * ------------------------------------------------------------------------------------
 *                   Changed By
 *      Date         Initials    Version #  Sub-Version  Notes
 *      -----------  ----------  ---------  -----------  ------------------------------
 *      2022-11-15   BL          22319      1            Re-structured files and renamed to index.js
 *
 */
import React, { useEffect } from "react";
import { Row, Col, Form, FormGroup, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import IndeterminateButton from "../IndeterminateButton";
import ValidatingField from "../ValidatingField";
import { useEmailAFriend } from "../../hooks/useEmailAFriend";
import useToggle from "../../hooks/useToggle";
import { useTrackEvent } from "../../hooks/useTrackEvent";
import AssemblyProvider from "../AssemblyProvider";

function EmailAFriend({ listingId, closeModal, hasBeenSubmitted = false }) {
    const [submitted, toggleSubmitted] = useToggle(hasBeenSubmitted);
    const contactForm = useForm({ mode: "all" }); // Must set mode to all for it to validate on blur of all fields. This is to be able to enable/disable the submit button
    const {
        handleSubmit,
        setError,
        formState: { isValid, isDirty },
    } = contactForm;
    const { execute, isLoading } = useEmailAFriend();
    const { trackEvent, trackGTM } = useTrackEvent();

    useEffect(() => {
        if (isDirty) {
            trackGTM({
                event: `formStarted`,
                action: "type",
                category: "contact",
                type: "Email A Friend",
                listingId,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDirty]);

    const onSubmit = async (data) => {
        const response = await execute(listingId, {
            body: JSON.stringify(data),
        });
        if (response?.entryDefects) {
            response.entryDefects.forEach((defect) => {
                const name = Object.keys(defect)[0];
                setError(name, { message: defect[name] });
            });
        } else {
            toggleSubmitted();
            trackGTM({
                event: `formSubmit`,
                action: "submit",
                category: "contact",
                type: "Email A Friend",
                button_label: "Email a Friend",
                listingId,
            });
            trackEvent("CONTACT", {
                SOURCE_INFO: {
                    eventAction: "submit",
                    eventLabel: "Email a Friend",
                    eventValue: listingId,
                },
            });
            if (closeModal) setTimeout(() => closeModal(), 2500);
        }
    };

    return (
        <AssemblyProvider
            group="EMAIL_LISTING2FRIEND"
            name="EMAIL_LISTING2FRIEND"
        >
            {(assembly) => (
                <>
                    {submitted ? (
                        <>
                            <div className="h4">Thank you for sharing!</div>
                            <div className="d-flex my-3">
                                {closeModal && (
                                    <Button
                                        color="primary"
                                        className="ms-auto"
                                        onClick={() => closeModal()}
                                    >
                                        Close
                                    </Button>
                                )}
                            </div>
                        </>
                    ) : (
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col sm>
                                    <ValidatingField
                                        className="mb-sm-2"
                                        name="first_name"
                                        assembly={assembly}
                                        {...contactForm}
                                    />
                                </Col>
                                <Col sm>
                                    <ValidatingField
                                        className="mb-sm-2"
                                        name="last_name"
                                        assembly={assembly}
                                        {...contactForm}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ValidatingField
                                        className="mb-sm-2"
                                        name="email"
                                        assembly={assembly}
                                        {...contactForm}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ValidatingField
                                        className="mb-sm-2"
                                        name="friend_name"
                                        assembly={assembly}
                                        {...contactForm}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ValidatingField
                                        className="mb-sm-2"
                                        name="friend_email"
                                        assembly={assembly}
                                        {...contactForm}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ValidatingField
                                        className="mb-sm-2"
                                        name="comments"
                                        type="textarea"
                                        assembly={assembly}
                                        {...contactForm}
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center mt-3">
                                <Col className="d-flex">
                                    <FormGroup className="ms-auto">
                                        <IndeterminateButton
                                            type="submit"
                                            className="btn btn-primary"
                                            value="Create Account"
                                            disabled={!isValid || isLoading}
                                            isLoading={isLoading}
                                        >
                                            Send
                                        </IndeterminateButton>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </>
            )}
        </AssemblyProvider>
    );
}

export default EmailAFriend;
