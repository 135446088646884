import React from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Button } from "reactstrap";
import { useMapContext } from "../../../contexts/map";
import { useTrackEvent } from "../../../hooks/useTrackEvent";

function Zoom() {
    const {
        state: { map },
    } = useMapContext();
    const { trackGTM } = useTrackEvent();

    const handleClick = (direction = "In", amount = 1) => {
        const zoomLevel = map.getZoom();
        const newZoomLevel =
            direction === "In" ? zoomLevel + amount : zoomLevel - amount;
        map.setZoom(newZoomLevel);
        trackGTM({
            event: `zoom`,
            action: "click",
            category: "mapInteraction",
            type: `Map Controls Zoom ${direction}`,
            zoomLevel: newZoomLevel,
        });
    };

    return (
        <div className="d-flex flex-column">
            <Button
                color="primary"
                className="inverse px-3 py-2 mb-1"
                onClick={() => handleClick()}
            >
                <FaPlus />
            </Button>
            <Button
                color="primary"
                className="inverse px-3 py-2"
                onClick={() => handleClick("Out")}
            >
                <FaMinus />
            </Button>
        </div>
    );
}

export default Zoom;
