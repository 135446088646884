import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTrackEvent } from "../../hooks/useTrackEvent";

/**
 * Cordless Media display ad.
 * https://ads.cordlessmedia.com/documentation/index.html#display.md
 * This is just the html tag portion of the implementation. The JS is added in Google Tag Manager.
 */
const DisplayAd = () => {
    const { trackGTM } = useTrackEvent();
    const nicheDoman = useSelector(
        (state) => state.root.styling.nicheDomain2ndLevel
    );
    const adId =
        nicheDoman === "beach-homes"
            ? "div-gpt-ad-1677257471564-0"
            : "div-gpt-ad-1673394784821-0";

    useEffect(() => {
        trackGTM({
            event: "adDisplay",
            type: "cordlessMedia",
            location: "Listing Page",
        });
    }, []);

    return <div id={adId}></div>;
};

export default DisplayAd;
