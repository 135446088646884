import React from "react";
import { useSelector } from "react-redux";
import { Accordion } from "reactstrap";
import { useQueryParam, StringParam } from "use-query-params";
import { useSearchData } from "../../../../hooks/useSearchData";
import AccordionNicheItems from "./NicheItems";
import AccordionListings from "./Listings";
import AccordionRegions from "./Regions";
import { useNacLocation } from "../../../../hooks/useNacLocation";

function StateAccordion() {
    const { state: { stateId } = {} } = useNacLocation();
    const stateRegions =
        useSelector((state) => state.pages.states?.[stateId]?.stateRegions) ||
        [];
    const nichetypePlural =
        useSelector(
            (state) => state.root.styling.nichetypePlural
        ).toLowerCase() || "niches";
    const { hasSearched } = useSearchData();
    const hasRegions = stateRegions.length > 0;
    const [mapView, setMapView] = useQueryParam("mapView", StringParam);
    const defaultView = hasSearched
        ? "listings"
        : hasRegions
        ? "regions"
        : nichetypePlural;
    const open = mapView || defaultView;
    const toggle = (id) => {
        if (id !== open) {
            setMapView(id);
        } else if (hasSearched) {
            setMapView(id === "listings" ? nichetypePlural : "listings");
        } else if (hasRegions) {
            setMapView(id === "regions" ? nichetypePlural : "regions");
        } else {
            setMapView();
        }
    };

    return (
        <Accordion open={open} toggle={toggle} className="rotate-ninety">
            <AccordionNicheItems
                id={nichetypePlural}
                stateId={stateId}
                isOpen={open === nichetypePlural}
            />
            {hasRegions && !hasSearched && (
                <AccordionRegions
                    id="regions"
                    stateId={stateId}
                    isOpen={open === "regions"}
                />
            )}
            {hasSearched && (
                <AccordionListings
                    id="listings"
                    stateId={stateId}
                    isOpen={open === "listings"}
                />
            )}
        </Accordion>
    );
}

export default StateAccordion;
