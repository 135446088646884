import React from "react";
import { Button } from "reactstrap";
import { useTrackEvent } from "../../hooks/useTrackEvent";

const Horizontal = ({
    className,
    agentName,
    agentName: {
        more: { id, uRL },
    },
    agentPic: { imageUrl },
    agentTitle_part1,
    agentTitle_part2,
    agentWebsitePhone,
    agentMobilePhone,
    agentOfficePhone,
    handleContact,
    handleLink,
    brokerageName,
    showPhoneAsLink,
    showButtons = true,
    showAgentTitle = true,
}) => {
    const { trackEvent, trackGTM } = useTrackEvent();

    const handlePhoneClick = () => {
        trackGTM({
            event: `phoneClick`,
            action: "click",
            type: "Featured Agent Phone",
            category: "contact",
            button_label: agentName.value,
            value: agentName.value,
            ...agentName,
        });
        trackEvent("CONTACT", {
            SOURCE_INFO: {
                eventAction: "click",
                eventLabel: "Featured Agent Phone",
                eventValue: agentName.value,
            },
        });
    };

    return (
        <div className={className}>
            {(agentTitle_part1 || agentTitle_part2) && showAgentTitle && (
                <div className="text-muted">
                    {agentTitle_part1?.value} {agentTitle_part2?.value}
                </div>
            )}
            <div className="d-flex align-items-center">
                <div className="me-2">
                    {imageUrl && (
                        <img
                            src={imageUrl}
                            alt={agentName.value}
                            height="55px"
                            onClick={(e) => handleLink(e, id, uRL)}
                        />
                    )}
                </div>
                <div className="me-2" style={{ lineHeight: "1.2" }}>
                    {agentName && (
                        <Button
                            className="text-start text-truncate border-0 m-0 p-0"
                            style={{ lineHeight: "1.2" }}
                            color="link"
                            href={uRL}
                            onClick={(e) => handleLink(e, id, uRL)}
                        >
                            {agentName.value}
                        </Button>
                    )}
                    {brokerageName && (
                        <div className="text-muted text-nowrap">
                            {brokerageName?.value}
                        </div>
                    )}
                    {(agentWebsitePhone ||
                        agentMobilePhone ||
                        agentOfficePhone) &&
                        showPhoneAsLink && (
                            <Button
                                className="p-0"
                                color="link"
                                style={{ lineHeight: "1.2" }}
                                onClick={() => handlePhoneClick()}
                                href={`tel:${
                                    agentWebsitePhone
                                        ? agentWebsitePhone.value
                                        : agentMobilePhone
                                        ? agentMobilePhone?.value
                                        : agentOfficePhone?.value
                                }`}
                            >
                                {agentWebsitePhone
                                    ? agentWebsitePhone.value
                                    : agentMobilePhone
                                    ? agentMobilePhone?.value
                                    : agentOfficePhone?.value}
                            </Button>
                        )}
                </div>
                {showButtons && (
                    <div className="d-flex align-content-center ms-auto">
                        <div>
                            <Button
                                className="inverse px-1 py-1 w-100 mb-1 text-nowrap"
                                color="primary"
                                href={uRL}
                                onClick={(e) => handleLink(e, id, uRL)}
                            >
                                View My Listings
                            </Button>
                            {(agentWebsitePhone ||
                                agentMobilePhone ||
                                agentOfficePhone) &&
                                !showPhoneAsLink && (
                                    <Button
                                        className="inverse px-1 py-1 w-100 mb-1"
                                        color="primary"
                                        onClick={() => handlePhoneClick()}
                                        href={`tel:${
                                            agentWebsitePhone
                                                ? agentWebsitePhone.value
                                                : agentMobilePhone
                                                ? agentMobilePhone?.value
                                                : agentOfficePhone?.value
                                        }`}
                                    >
                                        {agentWebsitePhone
                                            ? agentWebsitePhone.value
                                            : agentMobilePhone
                                            ? agentMobilePhone?.value
                                            : agentOfficePhone?.value}
                                    </Button>
                                )}
                            <Button
                                className="inverse px-1 py-1 w-100"
                                color="primary"
                                onClick={() => handleContact("Featured Agent")}
                            >
                                Contact Agent
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Horizontal;
