import React from "react";
import PropertyType from "../../PropertyType";
import { useSearchQueryParam } from "../../../../hooks/useSearchQueryParam";

/**
 * The Property Type search component.
 * @param {function} toggleIsOpen - The function that gets called to toggle open/shut.
 * @returns The Property Type component that is used in the Horizontal Search.
 */
const PropertyTypeSearch = ({ toggleIsOpen }) => {
    const [property_type, setPropertyType] =
        useSearchQueryParam("property_type");
    const handleSetPropertyType = (value, options) => {
        if (value !== options[0].value) {
            setPropertyType(value);
        } else {
            setPropertyType();
        }
    };

    return (
        <PropertyType
            toggleIsOpen={toggleIsOpen}
            propertyType={property_type}
            handleSetPropertyType={handleSetPropertyType}
        />
    );
};

export default PropertyTypeSearch;
