import React from "react";
import Waterfront from "../../Waterfront";
import { useSearchQueryParam } from "../../../../hooks/useSearchQueryParam";

/**
 * The Waterfront search component.
 * @param {function} toggleIsOpen - The function that gets called to toggle open/shut.
 * @param {boolean} isToggle - Determines whether the component should be styled as a toggle button or not.
 * @returns The Waterfront component that is used in the Horizontal Search.
 */
const WaterfrontSearch = ({ toggleIsOpen, isToggle }) => {
    const [waterfront, setWaterfront] = useSearchQueryParam("waterfront");

    return (
        <Waterfront
            toggleIsOpen={toggleIsOpen}
            isToggle={isToggle}
            isWaterfront={waterfront}
            handleSetIsWaterfront={setWaterfront}
        />
    );
};

export default WaterfrontSearch;
