import React from "react";
import { Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
    FacebookShareButton,
    TwitterShareButton,
    PinterestShareButton,
    EmailShareButton,
} from "react-share";
import {
    FaFacebook,
    FaXTwitter,
    FaPinterest,
    FaRegEnvelope,
    FaLink,
} from "react-icons/fa6";
import styled from "styled-components";
import CopyLinkButton from "../../CopyLinkButton";
import Drawer from "../../Drawer";
import EmailAFriend from "../../EmailAFriend";
import useToggle from "../../../hooks/useToggle";
import { useTrackEvent } from "../../../hooks/useTrackEvent";
import { getShareUrl } from "../../../support/helpers";

const Share = ({
    onClosed,
    children,
    media,
    onClick,
    listingId,
    pageHeaderInfo,
    ...props
}) => {
    const [modalIsOpen, toggleModal] = useToggle(false);
    const shareURL = getShareUrl();
    const { trackEvent, trackGTM } = useTrackEvent();

    const handleSocialShare = (type) => {
        trackGTM({
            event: `share`,
            action: "click",
            type: type,
            category: "social",
            button_label: children[1] || undefined,
            listingId,
        });
        trackEvent("ACTIONS", {
            CATEGORY: "social",
            ASSOCIATE: type,
            ACTION: "Share",
            SOURCE_INFO: { socialTarget: shareURL },
        });
    };

    return (
        <>
            <Button
                {...props}
                onClick={() => {
                    if (onClick) onClick();
                    toggleModal();
                }}
            >
                {children}
            </Button>
            <Drawer
                isOpen={modalIsOpen}
                toggle={() => toggleModal()}
                onClosed={() => {
                    if (onClosed) onClosed();
                }}
                headerText={"Share"}
            >
                <ModalBody>
                    <EmailAFriend
                        listingId={listingId}
                        closeModal={() => toggleModal(false)}
                    />
                    <div className="d-none d-md-flex col justify-content-around border-top pt-3">
                        <HoverButton>
                            <CopyLinkButton alt="Copy Link">
                                <FaLink size={32} />
                            </CopyLinkButton>
                        </HoverButton>
                        <HoverButton>
                            <FacebookShareButton
                                url={shareURL}
                                alt="Facebook Share"
                                onClick={() => handleSocialShare("Facebook")}
                            >
                                <FaFacebook size={32} />
                            </FacebookShareButton>
                        </HoverButton>
                        <HoverButton>
                            <TwitterShareButton
                                url={shareURL}
                                alt="Twitter Share"
                                onClick={() => handleSocialShare("Twitter")}
                            >
                                <FaXTwitter size={32} />
                            </TwitterShareButton>
                        </HoverButton>
                        {media && (
                            <HoverButton>
                                <PinterestShareButton
                                    url={shareURL}
                                    alt="Pinterest Share"
                                    media={media}
                                    onClick={() =>
                                        handleSocialShare("Pinterest")
                                    }
                                >
                                    <FaPinterest size={32} />
                                </PinterestShareButton>
                            </HoverButton>
                        )}
                        <HoverButton>
                            <EmailShareButton
                                url={shareURL}
                                alt="Email Listing"
                            >
                                <FaRegEnvelope
                                    size={32}
                                    onClick={() => handleSocialShare("Email")}
                                />
                            </EmailShareButton>
                        </HoverButton>
                    </div>
                </ModalBody>
                {shareURL && (
                    <div className="d-md-none">
                        <ModalFooter>
                            <div className="d-flex col justify-content-around">
                                <CopyLinkButton alt="Copy Link">
                                    <FaLink size={24} />
                                </CopyLinkButton>
                                <FacebookShareButton
                                    url={shareURL}
                                    alt="Facebook Share"
                                    onClick={() =>
                                        handleSocialShare("Facebook")
                                    }
                                >
                                    <FaFacebook size={24} />
                                </FacebookShareButton>
                                <TwitterShareButton
                                    url={shareURL}
                                    alt="Twitter Share"
                                    onClick={() => handleSocialShare("Twitter")}
                                >
                                    <FaXTwitter size={24} />
                                </TwitterShareButton>
                                {media && (
                                    <PinterestShareButton
                                        url={shareURL}
                                        alt="Pinterest Share"
                                        media={media}
                                        onClick={() =>
                                            handleSocialShare("Pinterest")
                                        }
                                    >
                                        <FaPinterest size={24} />
                                    </PinterestShareButton>
                                )}
                                <EmailShareButton
                                    url={shareURL}
                                    alt="Email Listing"
                                >
                                    <FaRegEnvelope
                                        size={24}
                                        onClick={() =>
                                            handleSocialShare("Email")
                                        }
                                    />
                                </EmailShareButton>
                            </div>
                        </ModalFooter>
                    </div>
                )}
            </Drawer>
        </>
    );
};

const HoverButton = styled.div`
    & button {
        opacity: 80%;
        &:hover {
            opacity: 1;
        }
    }
`;

export default Share;
